<template>
  <div class="widgetContainer bg-white widgetContainer--scrollable pullFunds">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <p class="title">
          {{ $t('pull_fund_row_title') }}
        </p>
        <span class="el-icon-close" @click="drawerClose" />
      </div>
    </div>

    <div class="widgetContainer__body">
      <template v-if="getSelfContacts.length !== 0">
        <h1 class="header-1">
          {{ $t('pull_fund_headerTitle') }}
        </h1>

        <div class="checkList">
          <el-radio-group v-model="selectedBank" @change="bankSelect($event)">
            <el-radio v-for="contact in getSelfContacts" :key="contact.id" :label="contact">
              <span class="checkList__circle center"><span class="icon-tick" /></span>
              <span class="checkList__name"> {{ contact.ach.bankName }}</span>
              <span>{{ contact.ach.accountNumber | mask(3) }}</span>
              <p class="remove" @click="removeContact(contact.id)">
                {{ $t('pull_fund_removelinkAccount_btnTitle') }}
              </p>
            </el-radio>
          </el-radio-group>
        </div>
      </template>

      <h1 class="header-2">
        {{ $t('pull_fund_linkAccount_headerTitle') }}
      </h1>

      <ul class="listSimpleArrows no-status" @click="addOwner">
        <li>
          <p>{{ $t('pull_fund_linkAccount') }}</p>
          <span class="icon-Arrow-small-right" />
        </li>
      </ul>

      <!-- Add Bank Account using Plaid -->
      <PlaidLink
        client-name="Wise"
        :on-success="onSuccess"
        :on-event="onEvent"
        ref="plaidLinkRef" />
    </div>
    <div class="el-drawer__footer">
      <el-button
        :disabled="!isFormValid"
        type="primary"
        @click="goToAmount"
        class="el-button__brand brand width-100">
        {{ $t('next') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import PlaidLink from '@m/fund/components/PlaidLink.vue';
import { mapGetters, mapMutations } from 'vuex';
import plaid from '../mixins/plaid';
export default {
  components: {
    PlaidLink
  },
  mixins: [plaid],
  data() {
    return {
      selectedBank: null
    };
  },
  computed: {
    ...mapGetters('account', ['getSelectedAccount']),
    ...mapGetters('contact', ['getSelfContacts']),
    isFormValid() {
      return this.selectedBank !== null;
    }
  },
  created() {
    setTimeout(() => {
      this.fetchContacts();
    }, 0);
  },
  methods: {
    ...mapMutations('fund', ['setPullFundDetails']),
    backAction() {
      this.drawerBack();
    },
    goToAmount() {
      if (this.isFormValid) {
        this.setPullFundDetails({ contact: this.selectedBank });
        this.drawerPush('pullFundsAmount')
      }
    },
    addOwner() {
      this.$refs.plaidLinkRef.openLink();
    },
    bankSelect(value) {
      this.selectedBank = value;
    }
  }
};
</script>

<style lang="scss">
.pullFunds {
  position: relative;

  .checkList {
    margin-bottom: 32px;
  }

  .listSimpleArrows {
    margin-top: 0;
  }
}
</style>
